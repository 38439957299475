import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loginActions } from '../../action/loginAction';
import { commonClass } from '../../helpers/commonClass';
// import { SetUserCurrency }  as '../../action/loginAction'; 


const Currencyselector = () => {

    const LoadLargeCValueCssFile = (event) => {
        if (event == "IDR" || event == "AED" || event == "TET" || event == "TEX") {
            let myid = document.getElementById('large_cu_css');
            if (myid == null || myid == undefined) {
                let css = document.createElement('link');
                css.id = "large_cu_css";
                css.href = './assets/css/prize-amt2.css?v1.2';
                css.rel = 'stylesheet';
                css.type = 'text/css';
                // document.head.appendChild(css);
                document.getElementById('myscpt').appendChild(css);
            }
        }
        else {
            let idr = document.getElementById('large_cu_css');
            if (idr != null && idr != undefined) {
                document.getElementById('large_cu_css').remove();
            }
        }
    }


    const getCurrencyName = (name) => {
        try {
            let value = localStorage.getItem(name);
            let curr_val = process.env.REACT_APP_CURRENCY;
            if (value) {
                value = JSON.parse(value)
                LoadLargeCValueCssFile(value.code)
                return value.code;
            }
            else {
                let event = { 'code': curr_val, "symbol": curr_val};
                localStorage.setItem('setuserCuncy', JSON.stringify(event))
                LoadLargeCValueCssFile(event.code)
                return curr_val;
            }
        }
        catch (ex) {
            let curr_val = process.env.REACT_APP_CURRENCY;
            let event = { 'code': curr_val, "symbol": curr_val};
            localStorage.setItem('setuserCuncy', JSON.stringify(event))
            LoadLargeCValueCssFile(event.code)
            return process.env.REACT_APP_CURRENCY;
        }
    };

    const [selectedLang, setSelectedCurrency] = useState(getCurrencyName("setuserCuncy"));
    const CurrencyList =commonClass.LoadCountry();
    //  [
    //     { 'code': 'AED', "symbol": "AED"},
    //     { 'code': 'THB', "symbol": "฿"},
    //     { 'code': 'IDR', "symbol": "Rp"},
    //     { 'code': 'TET', "symbol": "TET"},
    //     { 'code': 'TEX', "symbol": "TEX"}
        // { 'code': 'USD', "symbol": "$" },
        // { 'code': 'SAR', "symbol": "﷼" },
        // { 'code': 'PHP', "symbol": "₱" },
        // { 'code': 'VND', "symbol": "₫" },
        // { 'code': 'INR', "symbol": "₹" },
        // { 'code': 'MYR', "symbol": "RM" },
        // { 'code': 'BRL', "symbol": "R$" },
        // { 'code': 'OMR', "symbol": "﷼" },
        // { 'code': 'BHD', "symbol": ".د.ب" }
    // ];
    const dispatch = useDispatch();

    const changeCurrency = (event) => {
        try {
            LoadLargeCValueCssFile(event.code)
            let AvailableCurrn = localStorage.getItem('aedEqualCrnyValue');
            if (AvailableCurrn) {
                AvailableCurrn = JSON.parse(AvailableCurrn);
                if (AvailableCurrn && AvailableCurrn.length > 0) {
                    let AvailCu = AvailableCurrn.filter(a => a.currency_desc == event.code);
                    if (AvailCu.length == 0) {
                        let Obj = { 'code': 'AED', "symbol": "AED" };
                        StoredLocalStg(Obj)
                    }
                    else {
                        StoredLocalStg(event)
                    }
                }
                else {
                    let Obj = { 'code': 'AED', "symbol": "AED" };
                    StoredLocalStg(Obj)
                }
            }
            else {
                let Obj = { 'code': 'AED', "symbol": "AED" };
                StoredLocalStg(Obj)
            }
            setTimeout(() => {
                window.location.reload();
            }, 200);  //for get some translation from db
        } catch (error) {
            LoadLargeCValueCssFile(event.code)
            let Obj = { 'code': 'AED', "symbol": "AED" };
            StoredLocalStg(Obj)
        }
    }
    useEffect(() => {
        window.setCurrencyDoggle();
    }, [])

    const StoredLocalStg = (event) => {
        localStorage.setItem('setuserCuncy', JSON.stringify(event))
        dispatch(loginActions.SetUserCurrency(event))
        setSelectedCurrency(event.code);
    }




    return (
        <div className="selLanguage selLanguage2" onChange={changeCurrency} id="lstCurrency">
            <a className="selLangBtn Selcurrency">
                <img src="./assets/img/icons/curency.png?v0.1" />
                <span style={{ textTransform: 'uppercase' }} className="selectedLanguageName">{selectedLang == 'TET' ? 'ETHE' :selectedLang == 'TEX' ? 'USDT' : selectedLang}</span>
            </a>
            <ul className="selLangContCurr">
                {CurrencyList.map((x, inx1) => <>
                    <li><a onClick={() => changeCurrency(x)}><span>{x.code == 'TET' ? 'ETHEREUM' :x.code == 'TEX' ? 'USDT' : x.code}</span></a></li>
                </>
                )}
            </ul>
        </div>
    )
}

export default Currencyselector;
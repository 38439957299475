import React, { useState, useEffect } from 'react';
import Turnstile, { useTurnstile } from "react-turnstile";


const CTurnstile = ({ gettoken }) => {
    const [token, setToken] = useState('')
    const [SuccessResponse, setSuccessResponse] = useState('')
    const turnstile = useTurnstile();

    const handleTurnstileChange = (value) => {
        setToken(SuccessResponse);
        gettoken(SuccessResponse)
    }

    // const handleSubmit = async (event) => {
    //     event.preventDefault();
    //     setIsSubmitting(true);

    //     try {
    //         const response = await fetch('http://192.168.10.175:8100/v2/check_turnstile_token/', {
    //             method: 'POST',
    //             body: JSON.stringify({ token }),
    //             headers: { 'Content-Type': 'application/json' },
    //         });

    //         if (response.ok) {
    //             // Handle success
    //             console.log('Captcha verified successfully');
    //         } else {
    //             // Handle failure
    //             console.log('Captcha verification failed');
    //         }
    //     } catch (error) {
    //         console.error('Error:', error);
    //     } finally {
    //         setIsSubmitting(false);
    //     }
    // };

    console.log("process.env.REACT_APP_TRUNSTILE_SITE_KEY ==> ", process.env.REACT_APP_TRUNSTILE_SITE_KEY)
    return (
        <>
            <Turnstile
                sitekey={process.env.REACT_APP_TRUNSTILE_SITE_KEY}
                onVerify={(token) => {
                    fetch("http://192.168.10.175:8100/v2/check_turnstile_token/", {
                        method: "POST",
                        body: JSON.stringify({ token }),
                    }).then((response) => response.json())
                        .then((data) => {
                            if (data.success) {
                                setSuccessResponse(data);
                                gettoken(data)
                                console.log("Success:", data);
                            } else {
                                console.log("Error:", data);
                                turnstile.reset();
                            }
                        })
                        .catch((error) => {
                            console.error("Error during fetch:", error);
                            turnstile.reset();
                        });
                }}
            />
        </>
    );
}

export default CTurnstile;
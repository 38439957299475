import React, { Component } from 'react';
import { loginActions } from '../../action/loginAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Header from '../../Header/Header';
import { alertActions } from '../../action/AlertAction';
import { gameActions } from '../../action/GameAction';
class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            message: '',
            MaximummsgCount: 100
        }
    }

    handleTextChange(e) {
        try {
            let value = e.target.value
            if ([e.target.name] == "phone") {
                const regex = /^[0-9\b]+$/;
                if (!regex.test(value) && !value == '') { return; }
            }
            else if ([e.target.name] == "firstName" || [e.target.name] == "lastName") {
                const regex = /^[a-zA-Z ]*$/;
                if (!regex.test(value) && !value == '') { return; }
                // value = value.replace(/[^A-Za-z]/ig, '')
            }
            this.setState({ [e.target.name]: value });
        } catch (ex) { }
    }


    SumbitButton = (event) => {
        event.preventDefault();
        if (this.props.user.mobileNo == '0') {
            this.showMsg("lblPlsLogintoform");
            return;
        }
        if (this.state.firstName && this.state.firstName.trim() != "") {
            if (this.state.email && this.state.email.trim() != "") {
                if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)) {
                    if (this.state.phone && this.state.phone.trim() != "") {
                        if (this.state.message && this.state.message.trim() != "") {
                            let data = {}
                            this.state.message = this.state.message.replace(/'/g, "''")
                            data = { "first_name": this.state.firstName, last_name: this.state.lastName, "email": this.state.email, "mobileno": this.state.phone, "message_info": this.state.message }
                            this.props.UserContact(data);
                            this.setState({
                                firstName: '',
                                lastName: '',
                                email: '',
                                phone: '',
                                message: ''
                            })
                        }
                        else {
                            this.showMsg('lblsometxt');
                        }
                    }
                    else {
                        this.showMsg('lblValidMobileNo');
                    }
                }
                else {
                    this.showMsg('lblEnterValidEmail');
                }
            }
            else {
                this.showMsg('lblenterEmail');
            }
        }
        else {
            this.showMsg('lblEnterFirstname');
        }
    }

    showMsg = (msg) => { this.props.alertError(this.props.t(msg)); }


    sendEmail = () => {
        window.open("mailto:support@gulfticket.com?subject=SendMail&body=Description");
    };


    render() {
        const { t } = this.props;
        let messageText = this.state.message.trim().length;
        return (
            // <div className="col-md-9">
            //     <div className="MyaccountRht">
            //         <h3>About us</h3>
            //         <div className="InfotabSec">
            //             <ul>
            //                 <li><a className={this.state.sectionID == 1 ? "tabActive" : ""} onClick={() => { this.setSectionID(1) }}>{t('lblAbout')}</a></li>
            //                 <li><a className={this.state.sectionID == 2 ? "tabActive" : ""} onClick={() => { this.setSectionID(2) }}>{t('lblContactus')}</a></li>
            //             </ul>
            //         </div>
            //         <div className="myaccountInner">
            //             <div className="contInfo">
            //                 <h4>{t('lblLiveChat')}</h4>
            //                 <p>You can get in touch with us through LIVE CHAT.</p>
            //                 <button type="submit"><img src="./assets/img/icons/live-chat.webp" />Live Chat</button>
            //             </div>
            //             <div className="contInfo">
            //                 <h4>Email</h4>
            //                 <p>For any queries, please send an email to our customer support.</p>
            //                 <a href="mailto:support@gameandwin.ae">support@gameandwin.ae</a>
            //             </div>
            //             <div className="contInfo">
            //                 <h4>Call</h4>
            //                 <p>For any queries, please call to our customer care.</p>
            //                 <a href="tel:043378653">0-43378653</a>
            //             </div>
            //             <div className="contInfo">
            //                 <h4>Social media</h4>
            //                 <div className="socialMedia">
            //                     <a href="https://www.facebook.com/" target="_blank"><img src="./assets/img/icons/footer/fb.webp" /></a>
            //                     <a href="http://www.twitter.com/" target="_blank"><img src="./assets/img/icons/footer/tw.webp" /></a>
            //                     <a href="https://www.instagram.com/" target="_blank"><img src="./assets/img/icons/footer/insta.webp" /></a>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </div>
            <>
                <h2 className="headding3 floatNone">{t('lblContactus')}</h2>
                <div className="contactSec">
                    <div className="col-md-6 col-sm-12 col-xs-12">
                        <div className="hmAdd">
                            <ul>
                                {/* <li>
                                    <span><img src="./assets/img/icons/location.png" alt="GI Locarion Icon" /></span>
                                    <h2>{t('lbloffice')}</h2>
                                    <p>{t('lblCname')}</p>
                                    <p>{t('lbllocation')}</p>
                                </li> */}
                                <li>
                                    <span><img src="./assets/img/icons/mobile.png" alt="GI Mobile Icon" /></span>
                                    <h2>{t('lblCall')}</h2>
                                    <p><a href="tel:+91 9840960315" target="_blank" rel="noopener"><span style={{ width: "25px", marginTop: "-3px" }}></span>+971 542473561</a></p>
                                </li>
                                {/* <li><span><img src="./assets/img/icons/whatsapp.png" alt="GI Mail Icon" /></span>
                                    <h2>{t('lblwhatsapp')}</h2>
                                    <p><a target="_blank"  href="//api.whatsapp.com/send?phone=+971 542473561&text=WHATEVER_LINK_OR_TEXT_YOU_WANT_TO_SEND">+971 542473561</a></p>
                                </li> */}
                                <li><span><img src="./assets/img/icons/mail.png" alt="GI Mail Icon" /></span>
                                    <h2>{t('lblmail')}</h2>
                                    <p><a target="_blank" rel="noopener" onClick={() => { this.sendEmail() }}>support@gulfticket.com</a></p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12 forPadd8">
                        <div className="hmAdd">
                            <form className="contactForm" onSubmit={(e) => { this.SumbitButton(e) }} name="contactForm" id="contactForm" method="post" novalidate="novalidate">
                                <h6 className="formHeadding">{t('lblformhead')}</h6>
                                <div className="form-group row">
                                    <div className="col-md-12 field">
                                        <label className="lblName">{t('lblFirstName')}*</label>
                                        <input
                                            type="text"
                                            name="firstName" value={this.state.firstName}
                                            onChange={(e) => this.handleTextChange(e)}
                                            id="FirstName"
                                            // onkeypress="return (event.charCode > 64 &amp;&amp; event.charCode < 91) || (event.charCode > 96 &amp;&amp; event.charCode < 123)"
                                            className="form-control required"
                                            aria-required="true"
                                            maxLength={30}
                                            placeholder={t('lblFirstName')}
                                        />
                                    </div>
                                    <div className="col-md-12 field">
                                        <label className="lblName">{t('lblLastName')}</label>
                                        <input
                                            type="text"
                                            name="lastName" value={this.state.lastName}
                                            onChange={(e) => this.handleTextChange(e)}
                                            id="LastName"
                                            // onkeypress="return (event.charCode > 64 &amp;&amp; event.charCode < 91) || (event.charCode > 96 &amp;&amp; event.charCode < 123)"
                                            className="form-control required"
                                            aria-required="true"
                                            maxLength={30}
                                            placeholder={t('lblLastName')}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-12 field">
                                        <label className="lblName">{t('lblEmail')}*</label>
                                        <input type="email" name="email" placeholder={t('lblEmail')} value={this.state.email} maxLength={50} onChange={(e) => this.handleTextChange(e)} id="Email" className="form-control required" aria-required="true" />
                                    </div>
                                    <div className="col-md-12 field">
                                        <label className="lblName">{t('lblMobileNo')}*</label>
                                        <input type="text" name="phone" placeholder={t('lblwithcoun')} value={this.state.phone} maxLength={15} onChange={(e) => this.handleTextChange(e)} id="Phone" className="form-control required" 
                                            // onkeypress="allowNumbersOnly(event)" 
                                            aria-required="true" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-12 field">
                                        <label className="lblName">{t('lblmsg')}*</label><div className="paymentCards"><small>({messageText <= 0 ? t('lblmax') : t('lbltexteft')} {messageText <= 0 ? this.state.MaximummsgCount : this.state.MaximummsgCount - messageText})</small></div>
                                        <textarea name="message" placeholder={t('lblmsg')} maxLength={this.state.MaximummsgCount} value={this.state.message} onChange={(e) => this.handleTextChange(e)} id="message" cols="30" rows="10" className="form-control required" aria-required="true"></textarea>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-12 field">
                                        <div style={{ float: "right", marginTop: "10px" }} className="g-recaptcha" data-sitekey="6LdqrukgAAAAAALy9DjluJdG7uz2Jh1I-DrCXQ8Y"></div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-12 field">
                                        <div id="Alert1"></div>
                                        <div id="Loader1" style={{ display: "none" }}>
                                            <img src="img/loder.gif" width="50" height="50" alt='loder icon'/>
                                        </div>
                                        <button className="bg-game9 sendMsgBtn" name="send" id="send" type="submit">{t('lblsendmsg')}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
function mapStateToProps(state) {
    const { user } = state.loginReducer;
    return { user };
}
const mapActionToProps = {
    logout: loginActions.logout,
    alertError: alertActions.error,
    UserContact: gameActions.UserContact
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(ContactUs))